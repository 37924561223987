import { IRole } from "@/core/data/roles";
interface IUser {
  id: number;
  name: string;
  email: string;
  role: IRole;
  status: boolean;
}

const users: Array<IUser> = [
  {
    id: 1,
    name: "Naufal Rasyad",
    email: "naufalrasyad@gmail.com",
    role: {
      id: 1,
      name: "Superadmin",
      description: "Role for the highest order",
      status: true,
      userCount: 10,
      permissionCount: 40,
    },
    status: false,
  },
  {
    id: 2,
    name: "Naufal Rasyad",
    email: "naufalrasyad@gmail.com",
    role: {
      id: 1,
      name: "Superadmin",
      description: "Role for the highest order",
      status: true,
      userCount: 10,
      permissionCount: 40,
    },
    status: false,
  },
  {
    id: 3,
    name: "Naufal Rasyad",
    email: "naufalrasyad@gmail.com",
    role: {
      id: 1,
      name: "Superadmin",
      description: "Role for the highest order",
      status: true,
      userCount: 10,
      permissionCount: 40,
    },
    status: false,
  },
  {
    id: 4,
    name: "Naufal Rasyad",
    email: "naufalrasyad@gmail.com",
    role: {
      id: 1,
      name: "Superadmin",
      description: "Role for the highest order",
      status: true,
      userCount: 10,
      permissionCount: 40,
    },
    status: false,
  },
  {
    id: 5,
    name: "Naufal Rasyad",
    email: "naufalrasyad@gmail.com",
    role: {
      id: 1,
      name: "Superadmin",
      description: "Role for the highest order",
      status: true,
      userCount: 10,
      permissionCount: 40,
    },
    status: false,
  },
];
export { IUser };
export default users;

export interface IMember {
  name: string;
  username: string;
  email: string;
  id: string;
  is_active: boolean;
}
